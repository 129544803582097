@import "../../../variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");

.game {
  //background-color: #FF82E8;
  height: 100vh;
  background: linear-gradient(-45deg, #ff82e8, #8dcbfe, #ffb8f2, #fbe8b9);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .nav {
    height: 30px;
    position: relative;

    .logo {
      height: 90px;
      text-align: center;
      margin: 0 auto;
    }

    .closeButton {
      display: inline-block;
      cursor: pointer;
      z-index: 1000;
      padding: 20px;
      position: absolute;
      top: 0;
      left: 0;

      .anticon-close-circle {
        svg {
          width: 30px;
          height: 30px;
          fill: #fbfbfb;
        }

        &:hover {
          svg {
            fill: #404040;
          }
        }
      }
    }
  }

  .general-instructions {
    font-family: "Lilita One", cursive;
    font-size: 16px;
    margin: 8px auto;
    text-align: center;
    display: block;
    color: #5c5c5c;
  }

  .option-button {
    border-radius: 16px !important;
    background-color: #ffb8f2;
    color: $primary-black;
    border: 4px solid #ff71e4;
    margin: 8px 16px 0;
    width: calc(100% - 32px);
    padding: 6px 20px;
    line-height: 32px;
    height: auto;
    font-family: "Lilita One", cursive;
    font-size: 28px;

    &.ant-btn-primary,
    &:hover {
      border-color: $primary-off-white;
    }

    &:active {
      border-color: $primary-lila;
    }

    .element {
      display: table;
      table-layout: fixed;
      width: 100%;

      .truncate {
        display: table-cell;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .addNewSphere {
    display: grid;
    grid-template-columns: auto 160px;

    &_Input {
      background-color: #f5f6fa;
      color: $primary-black;
      border: 4px solid #ff71e4;
      outline: none;

      &:focus {
        border-color: #f5f6fa;
      }
    }
  }

  #game-area {
    height: 80vh;
    width: 100%;
    position: relative;
  }

  .restart-button {
    position: absolute;
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .restart-button-button {
      background-color: #f8d98a;
      border-radius: 16px;
      color: #000;
      padding: 16px;
      font-family: "Lilita One", cursive;
      font-size: 28px;
      cursor: pointer;

      &:hover {
        background-color: #ffa6ef;
      }
    }
  }

  .hidden {
    display: none;
  }

  .card {
    position: absolute;
    width: 100%;
    height: 80vh;
    will-change: transform;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card > .card-inner {
    background-color: white;
    background-size: auto 85%;
    background-repeat: no-repeat;
    background-position: center center;
    width: 45vh;
    max-width: 300px;
    height: 75vh;
    max-height: 570px;
    will-change: transform;
    border-radius: 10px;
    box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4),
      0 10px 10px -10px rgba(50, 50, 73, 0.3);
    overflow-y: auto;
    overflow-x: hidden;

    .card-body {
      padding: 20px;
      text-align: center;

      .avatar-placeholder {
        margin: 30px auto;
        display: inline-block;
        background-color: #f4c041;
        border-radius: 60px;
        height: 120px;
        width: 120px;
        margin-bottom: 0px;
      }

      h3 {
        margin-top: 30px;
        font-family: "Lilita One", cursive !important;
        font-size: 22px !important;
        word-break: break-all;

        span {
          display: block;
          font-family: "Lato", sans-serif;
          font-size: 18px;
          word-break: break-all;
        }
      }

      hr {
        margin: 40px 30px;
      }

      .conversation {
        background-color: #e2f2ff;
        border-radius: 16px;
        padding: 8px;
        word-break: break-all;

        h4 {
          font-weight: bold;
          font-size: 16px;
          word-break: break-all;
        }

        .subject {
          font-weight: bold;
          font-size: 14px;
          word-break: break-all;
        }
      }

      &.loading {
        pointer-events: none;
        opacity: 0.8;
      }
    }
  }

  .controls {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    .ant-btn {
      border: none;
      margin: 8px;
      padding: 8px;
      height: auto;
      font-family: "Lilita One", cursive;
      font-size: 22px;
      transition: all 0.3s ease-in-out, box-shadow 1s ease-out;

      small {
        display: block;
        font-size: 14px;
        opacity: 0.7;
      }

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
        background-color: #f5f5f5;
      }
    }
  }

  .filterRow {
    margin: 10px 20px;

    label {
      font-family: "Lilita One", cursive !important;
      font-size: 22px !important;
      color: #1f1f1f !important;
    }

    input {
      font-family: "Lilita One", cursive;
      border: none;
      background-color: transparent;
      color: #1f1f1f;
      border-bottom: 2px solid #1f1f1f;
      font-size: 18px;
    }
  }

  .spheresSection {
    max-height: calc(100vh - 200px);
    overflow-y: auto;

    &::-webkit-scrollbar-thumb {
      width: 4px !important;
      background-color: #ff71e4 !important;
    }

    &::-webkit-scrollbar-track {
      background: #ffb8f2;
    }
  }
}

.assigned-spheres {
  margin: 10px 0;
  text-align: left;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  h4 {
    margin-bottom: 5px;
    font-weight: bold;
  }

  .sphere-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    .sphere-tag {
      background-color: #ffb8f2;
      border: 1px solid #ff71e4;
      border-radius: 12px;
      padding: 2px 8px;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      position: relative;
      transition: all 0.2s ease-in-out;

      .sphere-status {
        font-size: 10px;
        margin-left: 5px;
        transition: all 0.2s ease-in-out;

        &.saving {
          color: #faad14;
        }

        &.saved {
          color: #52c41a;
        }
      }
    }
  }

  &.empty {
    opacity: 0.6;
  }

  .no-spheres {
    font-style: italic;
    color: #888;
  }

  .sphere-tag {
    &.saving {
      border-color: #faad14;
      animation: pulse 1s infinite;
    }

    &.saved {
      border-color: #52c41a;
    }
  }

  &.loading {
    opacity: 0.7;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.3);
      border-radius: 8px;
      animation: pulse 1s infinite;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.sphere-assigned {
  border-color: #1890ff;
  background-color: #e6f7ff !important;
  transition: all 0.3s ease-in-out !important;

  .sphere-assigned-icon {
    margin-left: 8px;
    transition: all 0.2s ease-in-out;

    // Different colors for status
    &.saving {
      color: #faad14;
    }

    &.saved {
      color: #52c41a;
    }
  }
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.button-flash {
  animation: button-flash-animation 1s ease-out;
}

@keyframes button-flash-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(24, 144, 255, 0.7);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(24, 144, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(24, 144, 255, 0);
  }
}
